import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/Layout"
import { buildBlocks } from "@/services/blockGenerator"
import { ButtonLink } from "@/components/reusable/Button"

type FacilityProps = {
  data: {
    wpFacility: Queries.WpFacility
    wp: Pick<Queries.Wp, "themeOptions"> & {
      themeOptions: Pick<Queries.WpThemeOptions, "ThemeOptions"> & {
        ThemeOptions: Pick<Queries.WpThemeOptions_Themeoptions, "siteLogo"> & {
          siteLogo: Pick<Queries.WpMediaItem, "mediaItemUrl">
        }
      }
    }
  }
  pageContext: {
    pagePath: string
    pageTitle: string
  }
}

export default function Facility({ data, pageContext }: FacilityProps) {
  if (!data) {
    console.error("No data found for page")
    return null
  }

  pageContext.pageTitle = data.wpFacility.Meta?.metaTitle
    ? data.wpFacility.Meta.metaTitle
    : data.wpFacility.title

  pageContext.facilityFields = data.wpFacility.facilityFields
  pageContext.serviceFields = false

  const generatedBlocks = buildBlocks(data.wpFacility.blocks, {}, pageContext)

  return (
    <Layout
      meta={data.wpFacility.Meta}
      path={pageContext.pagePath}
      title={data.wpFacility.title}
      themeOptions={data.wp.themeOptions}
    >
      {generatedBlocks}
      {data.wpFacility.floatingButton?.showButton && (
        <div
          className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
        >
          <ButtonLink
            link={data.wpFacility.floatingButton?.buttonLink?.url ?? ""}
            variant={`primary`}
          >
            {data.wpFacility.floatingButton?.buttonLink?.title}
          </ButtonLink>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpFacility(id: { eq: $id }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      facilityFields {
        featuredImage {
          uri
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.85
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
        facilityIcon {
          uri
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`
